// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import lazyWithLogout from "utils/lazyWithLogout";
//types
import { Route } from "types";
import { ActionList } from "types/actions";

//users
const UsersOrganization = Loadable(
  lazyWithLogout(() => import("views/users/Organizations/OrganizationList"))
);
const UsersOrganizationDetails = Loadable(
  lazyWithLogout(() => import("views/users/Organizations/OrganizationDetails"))
);
const UsersOrganizationEdit = Loadable(
  lazyWithLogout(() => import("views/users/Organizations/EditOrganization"))
);
const UsersOrganizationCreate = Loadable(
  lazyWithLogout(() => import("views/users/Organizations/CreateOrganization"))
);

const UsersEmployees = Loadable(
  lazyWithLogout(() => import("views/users/Employees/EmployeeList"))
);
const UsersEmployeeDetails = Loadable(
  lazyWithLogout(() => import("views/users/Employees/EmployeeDetails"))
);

const UserLeads = Loadable(
  lazyWithLogout(() => import("views/users/Leads/LeadList"))
);
const UserDebtors = Loadable(
  lazyWithLogout(() => import("views/users/Debtors/DebtorList"))
);

//dashboard
const DashboardApplications = Loadable(
  lazyWithLogout(() => import("views/dashboard/applications/ApplicationList"))
);
const DashboardCreateApplications = Loadable(
  lazyWithLogout(() => import("views/dashboard/applications/CreateApplication"))
);
const DashboardApplicationDetails = Loadable(
  lazyWithLogout(
    () => import("views/dashboard/applications/ApplicationDetails")
  )
);
const DashboardContracts = Loadable(
  lazyWithLogout(() => import("views/dashboard/contracts/ContractList"))
);
const DashboardContractDetails = Loadable(
  lazyWithLogout(() => import("views/dashboard/contracts/ContractDetails"))
);

const DashboardSignatures = Loadable(
  lazyWithLogout(() => import("views/dashboard/signatures/SignatureList"))
);
const DashboardSignatureDetails = Loadable(
  lazyWithLogout(() => import("views/dashboard/signatures/SignatureDetails"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: Route = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/users/organizations",
      element: <UsersOrganization />,
      actions: [ActionList.clients.fetch_all_clients],
    },
    {
      path: "/users/organizations/organization-details/:id",
      element: <UsersOrganizationDetails />,
      actions: [ActionList.clients.fetch_client],
    },
    {
      path: "/users/organizations/organization-edit/:id",
      element: <UsersOrganizationEdit />,
      actions: [
        ActionList.clients.fetch_client,
        ActionList.clients.update_client,
      ],
    },
    {
      path: "/users/organizations/organization-create",
      element: <UsersOrganizationCreate />,
      actions: [ActionList.clients.create_client],
    },
    {
      path: "/users/employees",
      element: <UsersEmployees />,
    },
    {
      path: "/users/employees/employee-details/:id",
      element: <UsersEmployeeDetails />,
    },
    {
      path: "/users/leads",
      element: <UserLeads />,
      actions: [ActionList.employees.fetch_all_employees],
    },
    {
      path: "/users/debtors",
      element: <UserDebtors />,
      actions: [ActionList.employees.fetch_all_employees],
    },
    {
      path: "/dashboard/applications",
      element: <DashboardApplications />,
      actions: [
        ActionList.applications.fetch_all_applications,
        ActionList.applications.fetch_all_applications_for_current_user,
      ],
    },
    {
      path: "/dashboard/applications/create-application/:id/:ordinalNumber",
      element: <DashboardCreateApplications />,
      actions: [ActionList.applications.create_application],
    },
    {
      path: "/dashboard/applications/edit-application/:id/:ordinalNumber",
      element: <DashboardCreateApplications />,
      actions: [ActionList.applications.create_application],
    },
    {
      path: "/dashboard/applications/application-details/:id/:ordinalNumber",
      element: <DashboardApplicationDetails />,
      actions: [
        ActionList.applications.fetch_application,
        ActionList.applications.fetch_application_status_history,
      ],
    },
    {
      path: "/dashboard/contracts",
      element: <DashboardContracts />,
    },
    {
      path: "/dashboard/contracts/contract-details/:id/:ordinalNumber/:applicationId",
      element: <DashboardContractDetails />,
    },
    {
      path: "/dashboard/signatures",
      element: <DashboardSignatures />,
    },
    {
      path: "/dashboard/signatures/signature-details/:id/:applicationId/:signatureType?/:enclosureId?",
      element: <DashboardSignatureDetails />,
    },
  ],
};

export default MainRoutes;
