// material-ui
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//third party
import { FormattedMessage } from "react-intl";

import useCheckVersion from "hooks/useCheckVersion";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

const VersionAlert = styled(Alert)(({ theme }) => ({
  position: "fixed",
  zIndex: 1500,
  padding: theme.spacing(2),
  width: "100%",
  top: 0,
  borderRadius: 0,
  "& .MuiAlert-icon": {
    alignSelf: { xs: "flex-start", md: "center" },
  },
  "& .MuiAlert-message": {
    flex: 1,
  },
}));

const UpdateButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.values.xs]: { width: "100%" },
}));
UpdateButton.defaultProps = {
  ...UpdateButton.defaultProps,
  size: "medium",
  variant: "contained",
};
// ==============================|| VERSION UPDATE ALERT ||============================== //

const VersionUpdateAlert = () => {
  const hasUpdate = useCheckVersion();

  const handleClick = () => {
    window.location.reload();
  };

  return (
    hasUpdate && (
      <VersionAlert variant="filled" color="warning" severity="warning">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>
            <FormattedMessage id="versionUpdateAlertText" />
          </Typography>
          <Box flexGrow={0} flexShrink={0}>
            <UpdateButton onClick={handleClick}>
              <FormattedMessage id="versionUpdateButtonText" />
            </UpdateButton>
          </Box>
        </Stack>
      </VersionAlert>
    )
  );
};

export default VersionUpdateAlert;
