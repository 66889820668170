declare global {
  interface Window {
    ym: (...args: any[]) => void;
  }
}

export const yandexMetrika = () => {
  (function (m:{[key: string]: any}, e:Document, t:string, r:string, i:string, k?:HTMLScriptElement, a?:Element) {
    m[i] =
      m[i] ||
      function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
    m[i].l = 1 * Number(new Date());
    for (var j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === r) {
        return;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (k = e.createElement(t) as HTMLScriptElement),
      (a = e.getElementsByTagName(t)[0]),
      (k.async = Boolean(1)),
      (k.src = r),
      a.parentNode?.insertBefore(k, a);
  })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  window.ym(97158962, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  });
};


export const googleTagManager = () =>{
  (function (w:{[key: string]: any}, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f:Element = d.getElementsByTagName(s)[0],
      j = d.createElement(s) as HTMLScriptElement,
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode?.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-TWGTTTTT");
}
