import { createBrowserRouter } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import ArticleRoutes from './ArticleRoutes';

// project import
import Loadable from 'ui-component/Loadable';

//utils
import { getAvailableRoutes } from 'routes/utils';
import lazyWithLogout from 'utils/lazyWithLogout';

//types
import { Route } from 'types';

const PagesLanding = Loadable(
  lazyWithLogout(() => import('views/pages/landing'))
);
const PrivacyPolicy = Loadable(
  lazyWithLogout(() => import('views/pages/landing/PrivacyPolicy'))
);
const Error = Loadable(
  lazyWithLogout(() => import('views/pages/maintenance/Error'))
);

const createRouter = () =>
  createBrowserRouter(
    [
      { path: '/', element: <PagesLanding />, errorElement: <Error /> },
      { path: '/privacy-policy', element: <PrivacyPolicy /> },
      {
        ...MainRoutes,
        children: getAvailableRoutes(MainRoutes?.children || []) as Route[],
      },
      LoginRoutes,
      ...ArticleRoutes,
    ],

    {
      basename: import.meta.env.VITE_APP_BASE_NAME,
    }
  );

export default createRouter;
