// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUsersGroup,
  IconUsers,
  IconUserShield,
  IconUsersPlus,
  IconUserDollar,
} from "@tabler/icons-react";

// type
import { NavItemType } from "types";
import { ActionList } from "types/actions";

const icons = {
  IconUsersGroup: IconUsersGroup,
  IconUsers: IconUsers,
  IconUserShield: IconUserShield,
  IconUsersPlus: IconUsersPlus,
  IconUserDollar: IconUserDollar,
};

// ==============================|| MENU ITEMS - USERS ||============================== //

const users: NavItemType = {
  id: "users",
  title: <FormattedMessage id="users" />,
  icon: icons.IconUsersGroup,
  type: "group",

  children: [
    {
      id: "debtors",
      title: <FormattedMessage id="debtors" />,
      type: "item",
      icon: icons.IconUserDollar,
      url: "/users/debtors",
      breadcrumbs: true,
      actions: [ActionList.employees.fetch_all_employees],
    },
    {
      id: "leads",
      title: <FormattedMessage id="leads" />,
      type: "item",
      icon: icons.IconUsersPlus,
      url: "/users/leads",
      breadcrumbs: true,
      actions: [ActionList.employees.fetch_all_employees],
    },
    {
      id: "organizations",
      title: <FormattedMessage id="organizations" />,
      type: "item",
      icon: icons.IconUsers,
      url: "/users/organizations",
      breadcrumbs: true,
      actions: [ActionList.clients.fetch_all_clients],
      children: [
        {
          id: "organization-details",
          title: <FormattedMessage id="organization-details" />,
          type: "item",
          url: "/users/organizations/organization-details/:id",
          breadcrumbs: true,
          actions: [ActionList.clients.fetch_client],
        },
        {
          id: "organization-edit",
          title: <FormattedMessage id="organization-edit" />,
          type: "item",
          url: "/users/organizations/organization-edit/:id",
          breadcrumbs: true,
          actions: [
            ActionList.clients.fetch_client,
            ActionList.clients.update_client,
          ],
        },
        {
          id: "organization-create",
          title: <FormattedMessage id="organization-create" />,
          type: "item",
          url: "/users/organizations/organization-create",
          breadcrumbs: true,
          actions: [ActionList.clients.create_client],
        },
      ],
    },
    {
      id: "employees",
      title: <FormattedMessage id="employees" />,
      type: "item",
      icon: icons.IconUserShield,
      url: "/users/employees",
      breadcrumbs: true,
      children: [
        {
          id: "employee-details",
          title: <FormattedMessage id="employee-details" />,
          type: "item",
          url: "/users/employees/employee-details/:id",
          hidden: true,
          breadcrumbs: true,
        },
      ],
    },
  ],
};

export default users;
