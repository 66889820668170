// project imports
import Loadable from 'ui-component/Loadable';
import lazyWithLogout from 'utils/lazyWithLogout';

const FactoringMarketInKaz = Loadable(
  lazyWithLogout(() => import('views/pages/articles/FactoringMarketInKaz'))
);

const FactoringToolForBusiness = Loadable(
  lazyWithLogout(() => import('views/pages/articles/FactoringToolForBusiness'))
);
const FactoringFinancialWeapon = Loadable(
  lazyWithLogout(() => import('views/pages/articles/FactoringFinancialWeapon'))
);
const FactoringCreditForBusiness = Loadable(
  lazyWithLogout(
    () => import('views/pages/articles/FactoringCreditForBusiness')
  )
);

// ==============================|| ARTICLES ROUTING ||============================== //

const ArticleRoutes = [
  {
    path: '/factoring-market',
    element: <FactoringMarketInKaz />,
  },

  {
    path: '/factoring-tool-for-business',
    element: <FactoringToolForBusiness />,
  },
  {
    path: '/factoring-financial-weapon',
    element: <FactoringFinancialWeapon />,
  },
  {
    path: '/factoring-credit-for-business',
    element: <FactoringCreditForBusiness />,
  },
];

export default ArticleRoutes;
